.root {
    display: flex;
    align-items: center;
    margin-right: 30px;
    padding: 7px 5px;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
}

.color {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 8px;
    
}

.active {
    border: 1px solid #EFEFEF;
    border-radius: 22px;
}