@import '/src/styles/config.scss';

.root {}

.card {
    overflow: hidden;
    box-shadow: 0px 12px 32px rgba(19, 26, 47, 0.2);
    border-radius: 12px;
    position: relative;
    margin-bottom: 12px;
    height: 200px;
    cursor: pointer;

    &:hover .delete {
        display: block;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(46, 46, 46);
        background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(29, 29, 29, 0) 100%);
        top: 0;
        transform: translateY(-100%);
        transition: .25s;
    }

    &:hover {

        &:before {
            transform: translateY(0%);
        }

    }


}



.imgWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
}

.img {
    max-width: 100%;
    object-fit: contain;
}

.delete {
    position: absolute;
    z-index: 9;
    top: 12px;
    right: 12px;
    cursor: pointer;
    display: none;
    stroke: #fff;
    transition: .3s;

    &:hover {
        stroke: red;
    }
}

.modal {
    width: 386px;
    height: 164px;
    padding: 0;
    padding-top: 16px;
    padding-bottom: 30px;
text-align: center;
    div {}

    span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */

        text-align: center;

        /* txt and icon 1 */

        color: #363949;
        margin-bottom: 30px;
    }

    .btnWrapper {
        margin-top: 30px;
display: flex;
justify-content: center;

        .btn {
        }

        .btn+.btn {
            margin-left: 20px;
        }

        .btnClose {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            text-align: center;
            width: 153px;
            height: 48px;
            color: #FFFFFF;
            background: #FE435B;
            border-radius: 8px;
            border: none;
            outline: none;
        }

        .btnDel {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            text-align: center;
            width: 153px;
            height: 48px;
            color: #FE435B;
            background: rgba(254, 67, 91, 0.2);
            border-radius: 8px;
            border: none;
            outline: none;
        }
    }
}

.name {}