@import '/src/styles/config.scss';

.icon {
    svg {
        stroke: $color-font-color;
    }
}

.activeIcon {
    svg {
        stroke: $color-red;
    }
}

.root {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 12px 10px 12px 30px;
   cursor: pointer;

    a{
        display: block;
        margin-left: 12px;
        text-decoration: none;
    }
}
.activeMenu {
    border-right: 2px solid $color-red;
}
.link {
    color: $color-font-color;
}