.root {
    max-height: 500px;
    overflow-y: auto;
    padding: 8px 8px;
    scroll-margin: 10px;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
    padding-right: 10px;
}

.itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 10px;
    border-radius: 10px;
    transition: .3s;

    &:hover {
        box-shadow: 0 0 12px rgba(0,0,0,.2);
    }
}

.imageBlock {
    display: flex;
    align-items: center;
}

.imageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 28px;
    width: 72px;
    height: 72px;
    border-radius: 6px;
    overflow: hidden;


    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.delete {
    cursor: pointer;
    stroke: #363949;
    transition: .2s;

    &:hover {
        stroke: red
    }
}