@import '/src/styles/config.scss';

.root {}

.wrapper {
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
}

.dropdownRoot {
    width: 100%;
    max-width: 100%;
}

.btnBlock {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dateBtn {
    background-color: #F3F5F7;
    color: $color-font-color;
    margin-right: 12px;
}

.active {
    background-color: rgba(254, 67, 91, 0.08);
    color: $color-red;
}

.btnBlock {
    // display: flex;
    // width: 100%;
    // justify-content: center;
}

.btn {
    padding: 0 145px;
}