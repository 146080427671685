.container {
    width: 100%;
    height: 175px;
    background-color: #F5F5F5;

    /* stroke 8% */
    border: 1px dashed rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
    cursor: pointer;

    p {
        text-align: center;
    }

}

.contentInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activeContainer {
    transition: .5s;
    box-shadow: inset 0 0 52px 10px rgb(178, 231, 167);
}

.reject {
    transition: .5s;
    box-shadow: inset 0 0 52px 10px rgb(231, 167, 167);
}

.label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
}