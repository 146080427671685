@import '/src/styles/config.scss';

.content {
    // height: 300px;
    overflow-y: scroll;
    
}



.topScreen {
    position: sticky;
    top: 80px;
    background-color: #fff;
    z-index: 99;
}

.addBtn {
    padding: 0 10px;
}

.addBtnTaxt {
    margin-left: 15px;
}

.cardsBlock {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
    gap: 24px;
    // overflow: scroll;
}