@import '/src/styles/config.scss';

.root {
    position: relative;
}

.inputWrapper {
    border: 1px solid rgba(0, 0, 0, 0.08);
    width: 100%;
    height: $element-height;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.before {
    margin-right: 5px;
    font-family: Roboto;
    font-size: 16px;

    font-weight: 400;
}

.input {
    border: none;
    width: 100%;
    display: flex;
    font-family: Roboto;
    font-size: 16px;
    flex: 1;
    font-weight: 400;
}

.error {
    border: 1px solid $color-red;
    color: $color-red;
}

.disabled {
    border: none;

}

.label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
}

.errorText {
    // position: absolute;
    bottom: -20px;
    color: red;
    left: 0;
    display: flex;
    max-width: 100%;
    text-overflow: ellipsis;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // bottom: 0;
    right: 17px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}