@import '/src/styles/config.scss';

.root {
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    padding: 16px;
    border: 1px solid rgba(230,230,230,1)
}

.infoItem {
    margin-bottom: 16px;
}

.checkbox {
    padding-bottom: 4px;
}

.listItem {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.delIcon {
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
}

.infoItemTop {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
}

.label {
    display: flex;
    align-items: center;
    flex: 1;
    padding-bottom: 4px;
    margin-left: 10px;
    border-bottom: 1px solid rgb(199, 199, 199);
    
}
.infoItemAddBlock {
    display: flex;
    align-items: center;
}
.addBtn {
    display: flex;
    cursor: pointer;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: rgba(254, 67, 91, 0.1);
    border-radius: 12px;
    position: relative;
    
    &:before, &:after {
        content: '';
        display: block;
        background-color: $color-red;
    }
    &:before {
        position: absolute;
        width: 14px;
        height: 2px;
    }
    &:after {
        position: absolute;
        width: 2px;
        height: 14px;
    }
}

.disabledAddBtn {
    cursor: initial;
}

.input {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(199, 199, 199);
}