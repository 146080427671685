@import '/src/styles/config.scss';

.root {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    // overflow: hidden;
    display: flex;
    padding-left: 248px;
}

.left {
    position: fixed;
    top: 0;
    left: 0;
    width: 248px;
    // padding-left: 33px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #f1f1f1;
    height: 100%;


}

.right {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100px;
    padding: 0 20px;
}

.block {
    display: flex;
}

// fix z-index
.top {
    min-height: 80px;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;


}

.leftMenuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0;
flex: 1
}

.menu {
    // padding-top: 50px;

}

.logOutBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logOut {
    cursor: pointer;
}

.innerContent {
    display: flex;
    flex: 1;
    margin: 10px;
    overflow: hidden;
}