@import '/src/styles/config.scss';

.root {}

.modalBody {
    width: 640px;
}

.modalInner {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.modalTitle {
    display: flex;
    justify-content: center;
    padding-top: 35px;
}

.titleElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

.chooseBtn {
    width: 181px;
}

.chooseBtnItself {
    width: 181px;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;

    /* card shadow dsh board */
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    border-radius: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    transition: .3s;
    &:hover {
        box-shadow: 0px 16px 24px rgba(5, 3, 31, 0.1);
        transform: scale(1.02);
    }
}

.btnNames {
    display: flex;
    flex-direction: column;
    align-items: center;
}


