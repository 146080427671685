.tableBody,
.saleTable {
    max-width: 100%;
}


.loading {
    overflow-y: auto;
    background-color: #fff;
    height: 100%;
    overflow-x: hidden;
}

.table {
    width: 100%;
    @mixin _desktop_big{
        
    }
}

.cell {
    text-align: start;
}

.headerTable {
    height: 30px;
    color: grey;
    font-weight: 500;
    
}