@import '/src/styles/config.scss';

.widgetWrapper {
    display: flex;
    width: 100%;
   margin-bottom: 16px;
    .widget {
        flex: 1;
        &:first-child {
            margin-right: 16px;
        }
    }
}

.noteRead {
    text-align: center;
    margin-bottom: 12px;
}

