@import '../../../styles/config.scss';

.select {
    background: #FFFFFF;
    border: 1.5px solid #ECE9F1;
    box-sizing: border-box;
    border-radius: 3px;
    height: 28px;
    width: 200px;
    position: relative;
    padding: 3px 32px 3px 16px;
    display: flex;
    align-items: center;
    
}

.label {
    display: flex;
    align-items: center;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 600;
}

.options {
    cursor: pointer;
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    
    padding: 5px 0;

    &:hover {
        background-color: #f1f1f1;
    }
    &:not(:first-child){
        border-top: 1px solid $color-grey;
        // margin-top: 10px;
    }
}

.drop {
    width: 100%;
    position: absolute;
    top: 110%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,.2);
    border-radius: 3px;
    // padding: 11px 16px;
    z-index: 10;
    box-sizing: border-box;

}
.open {
    box-sizing: border-box;
}

.bgcOdd {
    background-color: $color-grey;
}

.topLabel {
    display: flex;
    align-items: center;
}

.mark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    margin-left: 16px;
}

.activeMark {
    transform: rotateX(180deg) translateY(50%);
}

.color {
    width: 12px;
    height: 12px;
    // margin: 0 16px;
}

.colorLabel {
    padding: 0 16px;
}

.isWhite {
    border: 1px solid $color-font-color;
}

.checkFrame {
    display: flex;
    align-items: center;
    margin-left: 16px;
    span {
        margin-left: 8px;
    }
}