.screenRoot {
    padding: 0;

}
.rootProfile {
    overflow-y: auto;
    flex: 1;
    min-height: 100%;
}
.click {
    transition: all 1s;
    background: #cc394c;
}

.textEditorItem {}

.texEditorStyle {
    // border-radius: 10px;

}

.top {
    padding: 0 35px;
}

.root {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.tabsEditorWrapper {
    display: flex;
    flex-direction: column;
}

.textEditorItemNone {
    display: none;
}

.title {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    padding: 0 35px;


    /* txt and icon 1 */

    color: #363949;
    margin-bottom: 53px;

    span {
        color: #FE435B;
    }
}

.userProfile {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */


    /* txt and icon 1 */

    color: #363949;
    margin-bottom: 30px;

    p {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */


        /* txt and icon 3 */

        color: #A2A3A5;

    }
}

.contact {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */


    /* txt and icon 2 */

    color: rgba(19, 26, 47, 0.7);
    margin-bottom: 25px;

    h3 {
        margin-bottom: 4px;
    }

    div {
        margin-right: 60px;
    }
}

.bottom {
    background: #F5F5F5;
    padding: 35px 70px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 35px;



    .btn {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 6px 12px rgba(10, 14, 50, 0.03);
        border-radius: 16px;
        // width: 826px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
        padding: 0 6px;
        width: fit-content;


        .btnItem {
            border: none;
            outline: none;
            padding: 12px 30px;

            color: rgba(67, 81, 108, 0.5);
            background: #FFFFFF;
        }

        .__activeBtnItem {
            border: none;
            outline: none;
            padding: 12px 30px;
            background: #FE435B;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
            border-radius: 14px;
            color: #fff;
        }
    }

    .textEditor {
        flex-grow: 1;
    }


    .wrapperButton {
        display: flex;
        width: 100%;
        justify-content: center;

        .button {
            width: 345px;
            transition: all 1s;
        }
    }
}