@import '/src/styles/config.scss';

.modal {
    min-height: 0;
}

.btn {
    display: flex;
    width: 100%;

}

.icon {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, .04);
    width: 50px;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.iconLoad {
    flex: 1;
    padding: 0 20px;
    // width: 150px;
    font-weight: 500;
    height: 50px;
    background-color: #fff;
    border: 1px solid $color-red;
    border-radius: 10px;
}

.iconBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.errorIcon {
    transition: all 0.5s;
    color: red
}

.haveIcon {
    transition: all 0.5s;
    color: #393939;
}