.modalWrapper {
    display: flex;


    .photo {
        margin-right: 12px;
        height: 60px;
        width: 60px;
        min-height: 60px;
        min-width: 60px;

        img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
        }
    }

    .reviewsInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 300px;

        .topTitle {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
            width: 450px;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 120%;
                /* or 19px */


                /* txt and icon 1 */

                color: #363949;
            }

            .excurtion {
                font-weight: 600;
                font-size: 12px;
                line-height: 120%;
                /* or 14px */


                /* txt and icon 2 */

                color: rgba(19, 26, 47, 0.7);

            }

            .date {
                font-weight: 600;
                font-size: 12px;
                line-height: 120%;
                /* or 14px */


                /* txt and icon 2 */

                color: rgba(19, 26, 47, 0.7);
                margin-top: 5px;
            }
        }

        .description {
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            /* or 18px */

            letter-spacing: 0.02em;

            /* txt and icon 2 */

            color: rgba(19, 26, 47, 0.7);
            margin-top: 28px;
            margin-bottom: 23px;
            overflow-y: auto;
            max-height: 100%;

        }

        .answerInput {
            div {
                div {
                    div {
                        background: rgba(0, 0, 0, 0.10);


                        input {

                            background: #e5e5e5;
                        }
                    }
                }
            }
        }

        .bottomModal {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .rate {
                svg {
                    margin-right: 10px;
                }
            }

            .btn {


                button {
                    border-radius: 6px;
                    border: none;
                    outline: none;
                    line-height: 20px;
                }

                .delete {
                    background: #F3F5F7;
                    padding: 14px 26px;
                    margin-right: 16px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 120%;
                    /* or 19px */


                    /* txt and icon 2 */

                    color: rgba(19, 26, 47, 0.7);

                }

                .answer {
                    padding: 14px 22px;
                    background: #FE435B;
                    color: #fff;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

.answerMain {

    font-family: 'Montserrat';
    font-style: normal;

    font-size: 16px;
    line-height: 120%;
    /* or 19px */


    /* txt and icon 1 */

    color: #363949;

    max-height: 79px;
    overflow-y: auto;

    margin-bottom: 24px;

    p {
        display: inline
    }

    p:first-child {
        font-weight: 600;
    }

    p:last-child {
        font-weight: 300;
    }
}