.react-datepicker-wrapper {
    width: 100%;
    display: flex !important;
    
}

.react-datepicker-ignore-onclickoutside {
    width: 100%;
    padding: 10px;
    height: 48px;
    border-radius: 8px;
    outline: none;
    border: none;
    background-color: #F3F5F7;
}

.react-datepicker__input-container {
    input {
        width: 100%;
        padding: 10px;
        height: 48px;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #F3F5F7;
        cursor: pointer;
    }
}

/**
Arrows
*/

.react-datepicker__navigation-icon {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid black;

    &:before {
        border-color: black !important;
        border-width: 1px 1px 0 0;
        height: 5px;
        width: 5px;
    }
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {

    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(225deg);
    }
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {

    &:before {
        position: absolute;
        left: 7px;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

// HEADER
.react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    padding: 15px 0;
}

.react-datepicker {
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    /* Elevation 3 */
    box-shadow: 0px 8px 16px rgba(142, 141, 208, 0.12);
    border-radius: 12px;
}

.react-datepicker__current-month {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

// week days names
.react-datepicker__day-names {
    display: flex;
    padding: 0 6.4px;
}

.react-datepicker__day-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1n + 6) {
        color: red;
    }
}

.weekend {
    color: red;
}

//range
.react-datepicker__week {
    display: flex;
}

.react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0 !important;
    height: 24px;
    width: 24px;
    margin: 5px 0;
    padding: 10px 20px;

    background-color: #fff;
    color: black;
    &:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 24px;

    }

    &:hover {
        background-color: #fff;
        color: black;
        &:before {
            background-color: rgba(254, 67, 91, 0.2);
        }
    }
}

.react-datepicker__day--highlighted {
    background-color: #fff;
    color: black;
    &:before {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid #FE435B;
        background-color: rgba(254, 67, 91, 0.08);
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 24px;
        color: #FE435B;
    }

    &:hover {
        background-color: #fff;
        color: black;
        &:before {
            background-color: rgba(254, 67, 91, 0.2);
        }
    }

}

.react-datepicker__day--keyboard-selected:hover {
    background-color: #fff;
        color: black;
        &:before {
            background-color: rgba(254, 67, 91, 0.2);
        }
}

.react-datepicker__day--in-range {
    background-color: rgba(254, 67, 91, 0.08) !important;
    border-radius: 0;
    position: relative;

    &.workday {
        color: black;
    }
}

.react-datepicker__day--range-start.react-datepicker__day--in-range {

    &:before {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid #FE435B;
        background-color: rgba(254, 67, 91, 0.08);
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 24px;
        color: #FE435B;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        z-index: 9;
        background-color: #fff;
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 6px;
    }

}
.react-datepicker__day--in-range.react-datepicker__day--range-end {

    &:before {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid #FE435B;
        background-color: rgba(254, 67, 91, 0.08);
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 24px;
        color: #FE435B;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        z-index: 9;
        background-color: #fff;
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 6px;
        left: 34px;
    }

}

// start range
.react-datepicker__day--in-selecting-range {
    position: relative;
    &:hover {
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            border: 1px solid #FE435B;
            background-color: rgba(254, 67, 91, 0.08);
            border-radius: 0;
            // line-height: 0!important;
            height: 24px;
            width: 24px;
            color: #FE435B;
        }
    
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            z-index: 9;
            background-color: #fff;
            border-radius: 0;
            // line-height: 0!important;
            height: 24px;
            width: 6px;
        }
    }
}
.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start{
    background-color: #fff;
    color: black;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid #FE435B;
        
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 24px;
        color: #FE435B;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 6px;
        z-index: 9;
        background-color: rgba(254, 67, 91, 0.08);
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: calc(100% - 6px);
    }
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
    background-color: rgba(254, 67, 91, 0.08);
    border-radius: 0;
    color: black;
}
.react-datepicker__day--in-selecting-range:not(.workday){
    background-color: rgba(254, 67, 91, 0.08);
    border-radius: 0;
    color: red;
}
.react-datepicker__day--keyboard-selected {
    background-color: #fff;
    color: black;
    &:before {
        content: '';
        display: block;
        position: absolute;
        // border: 1px solid #FE435B;
        // background-color: rgba(254, 67, 91, 0.08);
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 24px;
        color: #FE435B;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        z-index: 9;
        // background-color: #fff;
        border-radius: 0;
        // line-height: 0!important;
        height: 24px;
        width: 6px;
    }
}

// clear
.react-datepicker__close-icon::after {
    content: 'X';
    background-color: #FE435B;
}