@import '/src/styles/config.scss';

.root {
    flex: 1;
    width: 100%;

}

.top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

/**
*table
*/

.tableBody,
.saleTable {
    width: 100%;
}

.table {
    max-height: 600px;
    overflow-y: auto;
}

.tableCellHeader {
    text-align: start;
    height: 35px;
    position: sticky;
    background-color: #fff;
    z-index: 9;
    top: 0;
}

.saleItemCell {
    padding: 10px 0;

    &:first-child {
        padding-left: 20px;
    }
}

.saleItem {
    &:hover {
        background-color: #f1f1f1;
    }
}