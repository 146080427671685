@import '/src/styles/config.scss';

.root {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    opacity: 1;
    border: 1px solid rgba(254, 67, 91, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .15s;

    &:hover{
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
}
.disable {
    opacity: .5;
}

.plus {
    position: relative;
    display: block;
    width: 10px;
    height: 2px;
    background-color: $color-red;
    border-radius: 2px;
   

    &:before {
        content: '';
        display: block;
        width: 2px;
        height: 10px;
        background-color: $color-red;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
       transform: translate(-50%, -50%);
    }
}
.minus {
    position: relative;
    display: block;
    width: 10px;
    height: 2px;
    background-color: $color-red;
    border-radius: 2px;
}