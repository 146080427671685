@import '/src/styles/config.scss';

.root {
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: transparent;
    width: 100%;
    height: $element-height;
    border-radius: 8px;
    background-color: #fff;
    font-family: Roboto;
    font-size: 16px;
    padding: 0 16px;
    font-weight: 400;
}

.error {
    border: 1px solid #FE435B;
}

.addTimeBlock {
    display: flex;
    align-items: center;
    padding-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.inputBlock {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input {
    width: 70px;
    border: none;
    font-weight: 500;
    font-size: 16px;
}

.btn {
    border-color: $color-red;
    color: $color-red;
    padding: 5px 15px;
    height: 36px;
}

.timeItem {
    padding: 4px 10px;
    background-color: #F3F5F7;
    border-radius: 60px;
    margin-left: 16px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #e4e7eb;
    }


}

.delIcon {
    margin-left: 8px;
    margin-top: 2px;
    cursor: pointer;
}

.label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
}