@import '/src/styles/config.scss';

.cardInfoWrapper {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 48px;
    // display: grid;
    // grid-template-columns: repeat(6, minmax(186px, 1fr));
    // align-items: center;
    // width: 100%;
    // padding: 0 25px 0 16px;
    // z-index: 1;

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.08);
        box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
        z-index: 2;
    }
}



.left {
    display: flex;
    justify-content: start;
}


.rigth {
    display: flex;
    justify-content: end;
}

.modal {
    width: 386px;
    height: 164px;
    padding: 0;
    padding-top: 16px;
    padding-bottom: 30px;
    text-align: center;

    div {}

    span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */

        text-align: center;

        /* txt and icon 1 */

        color: #363949;
        margin-bottom: 30px;
    }

    p {
        color: #FE435B;
        font-size: 16px;
    }

    .btnWrapper {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .btn {}

        .btn+.btn {
            margin-left: 20px;
        }

        .btnClose {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            text-align: center;
            width: 153px;
            height: 48px;
            color: #FFFFFF;
            background: #FE435B;
            border-radius: 8px;
            border: none;
            outline: none;
        }

        .btnDel {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            text-align: center;
            width: 153px;
            height: 48px;
            color: #FE435B;
            background: rgba(254, 67, 91, 0.2);
            border-radius: 8px;
            border: none;
            outline: none;
        }
    }
}

.modalHard {
    width: 486px;
    height: 199px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 3px;

    font-family: 'Montserrat';
    text-align: center;
    padding: 0 !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    // z-index: 2000;
    h3 {
        color: #363949;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        /* identical to box height, or 22px */


        color: #000000;
        margin-top: 16px;
    }

    .brtnWrapperHard {
        display: flex;
        column-gap: 10px;
        margin-top: 24px;
        margin-left: 30px;

        .deleteHard {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 218px;

            border-radius: 8px;
            background: #F3F5F7;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            /* or 19px */


            color: rgba(4, 7, 28, 0.8);
        }

        .closeHard {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 218px;
            background: #FE435B;

            border-radius: 8px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;


            color: #FFFFFF;
        }
    }
}

.name {
    overflow-wrap: break-word;
}

.cell {
    vertical-align: middle;
    min-width: 100px;
    padding: 10px 5px 10px 0px;
}

.iconCard {
    display: flex;
    justify-content: end;
    // padding-top: 7px;
    padding: 10px 20px;

    span {
        cursor: pointer;

        path {
            transition: .2s;
            stroke: #333;
        }

        margin-left: 20px;

        &:hover {
            path {
                stroke: $color-red;
            }
        }
    }
}