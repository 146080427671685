@import '/src/styles/config.scss';

.root {
    min-width: 300px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    height: 50px;
}

.edit {
    cursor: pointer;
}

.editBlock {
    margin-bottom: 16px;
}

.textArea {
    border: none;
    width: 100%;
    max-height: 180px;
    font-weight: 400;
    color: rgba(#131A2F, .7);
    resize: none;
    padding: 15px;
    border-radius: 8px;
    scroll-margin: 10px;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
    padding-right: 10px;
}
.active {
    background-color: rgb(247, 247, 247);
}
.userItem {
    text-transform: capitalize;
    color: $color-font-color;
    font-size: 12px;
    margin-bottom: 6px;
    font-weight: 600;
    color: grey
}

.usersBlock {
    position: relative;
    max-height: 150px;
    overflow-y: auto;
    padding-bottom: 45px;
    flex: 1
}
.gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 65px;
    z-index: 9;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.saveBtn {
    border: 1.5px solid rgba(254, 67, 91, 0.1);
    border-radius: 8px;
    color: $color-red;
    padding: 0 10px;
}