@import url('../../../styles/config.scss');

.titlePage {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #363949;
}

.cardWrapper {
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    overflow-y: auto;

    .cardItem {
        display: flex;
        width: 546px;
        height: 260px;
        padding: 18px 16px 16px 16px;
        background-color: #fff;
        border-radius: 7px;
        // border: 1px solid red;
        position: relative;
        transition: all 0.25s;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            border: 1px solid;
            background: #363949;
            opacity: 0.08;
            height: 1px;
            width: 514px;
            left: 50%;
            transform: translateX(-50%);
        }

        &:hover {
            // border: 1px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);


            .rigth {
                .bottom {
                    .delete {
                        background: #DC3545;
                        color: #fff;
                    }
                }
            }
        }

        .photo {
            margin-right: 12px;
            height: 60px;
            width: 60px;

            img {
                border-radius: 50%;
                height: 60px;
                width: 60px;
            }
        }

        .rigth {
            width: 100%;

            .top {
                display: flex;
                justify-content: space-between;

                .center {
                    .title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 120%;
                        /* or 19px */


                        /* txt and icon 1 */

                        color: #363949;
                        margin-right: 80px;
                    }

                    .excurtion {

                        font-weight: 600;
                        font-size: 12px;
                        line-height: 120%;
                        /* or 14px */


                        /* txt and icon 2 */

                        color: rgba(19, 26, 47, 0.7);

                    }


                }

                .date {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 120%;


                    color: rgba(19, 26, 47, 0.7);
                }
            }

            .description {
                margin-top: 14px;
                display: flex;
                text-overflow: ellipsis;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                max-height: 100% ;
                /* or 18px */

                letter-spacing: 0.02em;

                /* txt and icon 2 */

                color: rgba(19, 26, 47, 0.7);
                // max-width: 235px;
            }

            .bottom {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                /* or 19px */


                /* txt and icon 2 */

                color: rgba(19, 26, 47, 0.7);
                margin-top: 16px;

                .rate {

                    svg {
                        width: 13px;
                        height: 13px;
                        margin-left: 10px;
                    }
                }

                .delete {
                    padding: 14px 26px;
                    margin-right: 42px;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    border-radius: 6px;
                    transition: all 0.25s;
                    font-weight: 700;
                    font-size: 16px;

                }

                .answer {
                    background: #F3F5F7;
                    border-radius: 6px;
                    padding: 14px 22px 15px 22px;
                    outline: none;
                    border: none;
                    font-weight: 700;
                    font-size: 16px;

                }
            }
        }
    }


}

.readAll {
    color: #FE435B;
    font-weight: bold;
}