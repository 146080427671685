// @import '/src/styles/config.scss';

.modalParent {
    .root {
        display: none;

        &:last-child {
            display: flex;
        }
    }
}

.root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
}

.underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;
    max-width: 39.33333rem;
    max-width: 920px;
    margin: auto;
    padding: 30px 55px;
    padding-top: 60px;
    border-radius: 16px;
    max-height: 100%;
    z-index: 90000;

}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.close {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    padding: 0 30px;
    // padding-right: 20px;
    // padding-left: 20px;
    top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeBtn {
    position: absolute;
    top: 0px;
    right: 12px;
}

.body {
    // overflow-y: auto;
    min-height: 300px;

    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

    // padding: 2px 16px;
}