@import '/src/styles/config.scss';

.root {
    width: 100%;

}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 16px;
}

.topRight {
    display: flex;
}

.excurtions {
    display: flex;
    flex-wrap: wrap;
}

.btns {
    display: flex;
}

.plusBtn {
    margin-right: 10px;
}

.chartWrapper {
    position: relative;
}

.blurWrapper {
    position: absolute;
    top: 0;
    bottom: 60px;
    width: 60px;
    overflow: hidden;
}

.blurBlock {

    // background-color: white;
    // opacity: .5;
    width: 100%;
    height: 100%;
    z-index: 9999;
    backdrop-filter: blur(1.5px);
}

.delIcon {
    width: 24px;
    height: 24px;
    background: #FFFFFF;

    /* primary 8% */
    border: 0.695473px solid rgba(0, 123, 255, 0.08);
    box-sizing: border-box;
    border-radius: 2.78189px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
    transition: .2s;

    &:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
}