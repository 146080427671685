@import '/src/styles/config.scss';

.calendarBlock {
    display: flex;
    justify-content: center;
}

.root {

}

.block {
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    padding: 16px;
    border: 1px solid rgba(230,230,230,1)
}

