@import '/src/styles/config.scss';

.root {

}

.wrapper {
    background-color: #fff;
}

.label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
}