.barTooltip {
    // padding: 10px;
}

.barTooltipTop {
    padding: 10px;
    background-color: rgb(245, 245, 245);
}

.item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    border-bottom: 1px solid #c3c3c3;

}

.apexcharts_none_active {
    opacity: 0.5;
}

.tooltip_color {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 8px;
}
.tooltip_text {
    display: flex;
    align-items: center;
}

.tooltip_value {
    min-width: 50px;
}

.tooltip_line {
    display: block;
    min-width: 15px;
    margin: 0 10px;
    width: 100%;
    flex: 1;
    border-bottom: 1px solid;
}

.apexcharts-xcrosshairs {
    width: 10px;
}

.apexcharts-xaxistooltip-text {
    font-size: 10px !important;
    &::after{
        display: none !important;
        border: none !important;
    }
    &:before {
        content: '';
        display: block;
        border: none !important;
        position: absolute;
        top: -280px;
        height: 280px;
        width: 50px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.1;
        background-color: grey;

        backdrop-filter: blur(2px);
    }
}