.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
    flex: 1
}

.btn {
    display: flex;
    width: 100%;
}