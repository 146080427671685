@import '/src/styles/config.scss';

.root {
    position: relative;
}

.optionsBlock {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    background: #FFFFFF;
    border: 1.5px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    /* card shadow dsh board */
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    border-radius: 8px;
    z-index: 99;
}

.item {
    padding: 10px 16px;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);


        &:hover {
            background-color: rgb(250, 250, 250);
        }
    }
}

.error {
    border: 1px solid #FE435B;
    color: #FE435B;
}

.errorText {
    bottom: -20px;
    color: red;
    left: 0;
    display: flex;
    max-width: 100%;
    text-overflow: ellipsis;
}