@import '/src/styles/config.scss';

.topFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-right: 24px;

    .dropDown {
        margin-bottom: -21px;
    }

    .dateSort {
        display: flex;
        text-align: center;
    }
}

.tableBody {
    width: 100%;
}


.red {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #DC3545 !important;
    
}
.cardWrapper {
    border-radius: 6px;
    border: 16px solid #F3F5F7;
    padding: 24px 16px;
    padding-bottom: 0;
    flex: 1;
    max-height: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
}

.saleTable {
    display: flex;

    .tableCellHeader {
        flex: 1;
    }

    .tableCellHeaderDate {
        flex: 2;
    }
}

.tableSellBody {
    display: flex;

    .tableCellHeaderBody {
        flex: 1;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        color: #363949;

    }
}



.saleItem {
    display: flex;
    padding: 0 20px;
    margin-bottom: 16px;

    .saleItemCell {
        flex: 1;
    }
}

.btnClose {
    padding: 7px 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #DC3545;
    border-radius: 6px;
    background: rgba(220, 53, 69, 0.1);
    outline: none;
    border: none;
    transition: .2s;

    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, .05);
    }

    &:disabled {
        background-color: rgb(236, 235, 235);
        color: rgba(19, 26, 47, 0.7);
        box-shadow: none;
    }
}

.isBuyPlace {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-right: 18px;
    color: #1BB82B;
}

.noBuyPlace {
    padding: 7px 6px;
    background: #F5F5F5;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: 94px;
    height: 30px;
    color: #A3A3A3;
    margin-right: 12px;
}

.btnBuy {
    display: flex;
    align-items: center;
    justify-content: end;
}

.city {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(19, 26, 47, 0.7);

}

.dateColumn {
    text-align: end;
}

.wrapperCardItem {
    cursor: pointer;
    padding: 16px 12px 0 12px;
    overflow: "auto";
    max-height: "150px";
    width: "100%";
    overflow-x: "hidden";
    background: #FFFFFF;
    transition: .2s;

    &:hover {
        background-color: #fafafa;
    }


}

.tableSellBodySub {
    display: none;
}

.__activeTableSellBodySub {
    display: block;
    max-height: 193px;
    overflow-y: auto;
}

.borderWrapper {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.dateBody {
    margin-bottom: 9px;
}

.buy {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1BB82B !important;
}

.someBuy {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #d1d352 !important;
}

.notBuy {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #A3A3A3 !important;
}

.dropDown {
    width: 325px;
}

.dateSort {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    color: rgba(19, 26, 47, 0.7);
    align-items: center;
    column-gap: 12px;

    .dateSortItem {
        display: flex;
        align-items: center;

        p {
            margin-right: 6px;
        }

        div {

            width: 108px;
            overflow: hidden;

            div {
                height: 30px;
                background: #F5F5F5;

                input {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 120%;
                    /* identical to box height, or 17px */

                    background: transparent;
                    /* txt and icon 1 */

                    color: #363949;
                    width: 100px;
                    height: 30px;

                    &::placeholder {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 120%;
                        /* identical to box height, or 17px */


                        /* txt and icon 3 */

                        color: #A2A3A5;

                    }
                }
            }
        }
    }
}

.scroll {
    overflow-y: auto;
}

.decline {
    margin-right: 12px;
    color: $color-red
}