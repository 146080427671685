@import '../../../styles/config.scss';

.check {
    position: relative;
    width: 20px;
    height: 20px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid $color-grey;
    padding: 2px;
    box-sizing: border-box;
    overflow: hidden;
}

// .checkbox {
//     width: 16px;
//     height: 16px;
//     display: flex;
//     border-radius: 2px;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     background-color: #fff;
//     border: 1px solid $color-grey;
//     padding: 2px;
//     box-sizing: border-box;
// }

.check:hover {
    background-color: #f1f1f1;
}

.check.checked:hover {
    background-color: $color-red;
}

.check.checked {
    background-color: $color-red;
    border: 2px solid $color-red;
}

.mark {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.root {
    display: flex;
    align-items: center;
}

.label {
    cursor: pointer;
    margin-left: 12px;
    white-space: nowrap;
}

.labelLeft {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    /* identical to box height, or 22px */


    /* txt and icon 1 */

    color: #363949;
    margin-right: 9px;
    cursor: pointer;
    margin-left: 12px;
    white-space: nowrap;
}