@import '/src/styles/config.scss';

.root {
    display: flex;
    align-items: center;
}

.filter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    align-items: center;
    margin-right: 24px;
}

.dropdown {
    width: 300px;
}