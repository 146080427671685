@import '/src/styles/config.scss';

.root {}

.button {
    display: flex;
    width: 100%;
}

.calendar {
    z-index: 999999;
}

