@import '/src/styles/config.scss';

.root {
    border-radius: 6px;
    background-color: #F3F5F7;
    padding: 17px;


}
.content {
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    padding: 16px;
}