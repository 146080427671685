@import '../../../styles/config.scss';

.select {
    // border: 1px solid $color-grey;
    background: #F7F7F7;
    min-width: 220px;
    max-width: 450px;
    position: relative;
    padding: 0 42px 0 16px;
    height: $element-height;
    border-radius: 8px;
    display: flex;


    &:active {
        box-shadow: none;
    }
}

.label {
    padding: 5px 0px;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    font-size: 16px;

}

.modalParent {
    .root {
        display: none;

        &:last-child {
            display: flex;
        }
    }
}

/**
*
*/
.extraWrapper {
    padding: 2px;
    position: absolute;
    left: 100%;
    top: 0;
}

.extraMenu {
    overflow-y: auto;
    overflow-x: hidden;
    width: 250px;
    max-height: 350px;
    background-color: #fff;
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.2);
    display: none;

    &:hover {
        display: block;
    }

}


.itemWrapper {
    position: relative;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    color: #393939;

    &:not(:last-child) {
        border-bottom: 1px solid $color-grey;
    }

    &:hover {

        // border: 1px solid $color-red;.
        background-color: #fafafa;

        .extraMenu {
            display: block;
        }
    }
}

.itemIcon {
    transform: rotate(-90deg) translateY(-50%);
    position: absolute;
    right: 10px;
    top: 50%;

}

.options {
    display: flex;
    align-items: center;

    position: relative;
    cursor: pointer;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    padding: 12px 0;
    margin: 0 16px;
    justify-content: space-between;



    &:not(:first-child) {
        margin-top: 10px;
    }


}

.checkbox {

    margin-right: 12px;

}

.drop {
    width: 100%;
    position: absolute;
    top: 115%;
    left: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 0 0;
    z-index: 10;
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.2);

    scroll-margin: 10px;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
    padding-right: 10px;
    // border-top: 1px solid $color-grey;
}

.oneLevel {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bgcOdd {
    background-color: $color-grey;
}

.topLabel {
    display: flex;
    align-items: center;
    max-width: 100%;

}

.mark {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: .2s;
}

.activeMark {
    transform: rotateX(180deg) translateY(4px);
}

.placeholder {
    color: #c1c1c1;
    font-weight: 500 !important;
}

.value {
    font-weight: 500;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
}

.labelTop {
    display: flex;
    margin-bottom: 6px;
    color: $color-font-color;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat;
}

.label {
    font-weight: 600;
}


//input
.inputBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 8px;
    border-bottom: 1px solid #333;
    height: 44px;
    position: sticky;
    background-color: #fff;
    z-index: 9999;
    top: 0;

    button {
        margin-top: 0;
    }

    input {
        border: none;
        width: 100%;
    }
}

.input {
    font-weight: 500;
}

.disabled {
    color: #c1c1c1;
}

.delete {
    stroke: #c1c1c1;

    &:hover {
        stroke: $color-red;
    }
}

.edit {
    stroke: #c1c1c1;
    margin-right: 10px;

    &:hover {
        stroke: $color-red;
    }
}


.errorText {
    // position: absolute;
    bottom: -20px;
    color: red;
    left: 0;
    display: flex;
    max-width: 100%;
    text-overflow: ellipsis;
}

.error {
    border: 1px solid $color-red;
    color: $color-red;
}

.modal {
    width: 486px;
    height: 199px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 3px;

    font-family: 'Montserrat';
    text-align: center;
    padding: 0 !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    // z-index: 2000;
    h3 {
        color: #363949;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;

    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        /* identical to box height, or 22px */


        color: #000000;
        margin-top: 16px;
    }

    .brtnWrapper {
        display: flex;
        column-gap: 10px;
        margin-top: 24px;
        margin-left: 26px;

        .delete {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 210px;

            border-radius: 8px;
            background: #F3F5F7;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            /* or 19px */


            color: rgba(4, 7, 28, 0.8);
        }

        .edit {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 218px;

            border-radius: 8px;
            background: #F3F5F7;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            /* or 19px */


            path {
                stroke: rgba(0, 0, 0, 0.2)
            }

            color: rgba(4, 7, 28, 0.8);
        }

        .close {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 218px;
            background: #FE435B;

            border-radius: 8px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;


            color: #FFFFFF;
        }
    }
}