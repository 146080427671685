/*
=============================
Variables
=============================
*/

$menu-width: 80px;
$element-height: 48px;
$right-component: 400px;

/*
=============================
Breakpoints
=============================
*/

/*
=============================
Colors
=============================
*/

$color-font-color: #363949;
$color-grey: #A2A3A5;
$color-red: #FE435B;



/*
=============================
functions
=============================
*/

/*
=============================
Mixins
=============================
*/
@mixin _desktop_big {
    @media (max-width: 1280px) {
        @content;
    }

}

@mixin _desktop {
    @media (max-width: 1170px) {
        @content;
    }

}

@mixin _tablet {
    @media (max-width: 767px) {
        @content;
    }

}

@mixin _mobile {
    @media (max-width: 450px) {
        @content;
    }

}

@mixin _land {
    @media (orientation: landscape) {
        @content;
    }
}



// scrollbar

::-webkit-scrollbar {
    width: 8px;
    height: 8px;

}

::-webkit-scrollbar-button {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #fe435b;
    border: 0px none #ffffff;
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: #fe435b;
}

::-webkit-scrollbar-thumb:active {
    background: #fe435b;
}

::-webkit-scrollbar-track {
    background: #fff0f2;
    border: 0px none #ffffff;
    border-radius: 52px;
}

::-webkit-scrollbar-track:hover {
    background: #fff0f2;
}

::-webkit-scrollbar-track:active {
    background: #fff0f2;
}

::-webkit-scrollbar-corner {
    background: transparent;
}