@import '/src/styles/config.scss';

.root {
    position: relative;
}

.inputWrapper {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.08);
    width: 100%;
    height: $element-height;
    border-radius: 8px;
    background: #F3F5F7;
    display: flex;
    align-items: center;
    // padding: 0 16px;
}

.before {
    margin-right: 5px;
    font-family: Roboto;
    font-size: 16px;

    font-weight: 400;
}

.input {
    cursor: pointer;
    border: none;
    background: transparent;
    font-family: Roboto;
    font-size: 16px;
    flex: 1;
    font-weight: 400;
}

.error {
    border-bottom: 1px solid $color-red;
    color: $color-red;
}

.disabled {
    border: none;

}

.label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
}

.errorText {
    position: absolute;
    bottom: -20px;
    color: red;
    left: 0;
}

.wrapperPickerDate {
    position: relative;

    .dataPicker {

        div {
            div {
                border: none;
                background: transparent;
                box-shadow: none;

                div {
                    background: #fff;

                }
            }

        }
    }
}

.dataPicker {
    position: absolute;
    bottom: -272px;
    z-index: 1;
    display: flex;
    justify-content: center;
    left: -16px;

    div {
        div {
            div {
                div {
                    div {}
                }
            }
        }
    }

}