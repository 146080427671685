@import '/src/styles/config.scss';

.addBtnTaxt {
    margin-left: 15px;
}

.addBtn {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border: 1.5px solid #FE435B;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .15s;

    &:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, .2);
    }
}