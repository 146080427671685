.modalWrapper {
    .columnWrapper {
        display: flex;
        justify-content: space-between;

        .labelInput {

            div {
                div {
                    div {
                        &:last-child {

                            background: #F3F5F7;

                            margin-bottom: 0px;
                            input {
                                background: transparent
                            }
                        }
                    }
                }

                &:first-child {

                    margin-bottom: 14px;
                }
            }
        }

        .time {
            margin-top: -5px;
            margin-bottom: 33px;
        }

        .left,
        .right {
            .modal_item {
                div {
                    width: 388px;

                }
            }
        }

        .left {
            .modal_item_checkbox {
                margin-top: 78px;
                margin-left: 24px;
            }
        }
    }

    .wrapperButton {
        width: 325px;
        margin: 0 auto;

        .button {
            display: flex;
            width: 100%;
        }
    }
}