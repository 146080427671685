@import '/src/styles/config.scss';

.root {
    padding: 38px 20px 30px 38px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.title {
    font-weight: 700;
    font-size: 32px;
    color: #04071C;
   
}

.content {
    position: relative;
    flex: 1;
    overflow: hidden;
    scroll-margin: 10px;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
    
    // overflow-y: auto;
}

.top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 38px;
    position: sticky;
    top: 40px;
    z-index: 9;
    margin-bottom: 40px;
    background-color: #fff;
}